body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
    font-size: .875rem;
  }
  
  .feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
  }
  
  span.nav-link{
    cursor: pointer;
  }
  
  /*
   * Sidebar
   */
  
  .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  }
  
  @supports (position: sticky) {
    .sidebar-sticky {
      position: sticky;
    }
  }
  
  #sidebar .nav-link {
    font-weight: 500;
    color: #333;
  }
  
  #sidebar .nav-link .feather {
    margin-right: 4px;
    color: #999;
  }
  
  #sidebar .nav-link.active {
    color: #007bff;
  }
  
  #sidebar .nav-link:hover .feather,
  #sidebar .nav-link.active .feather {
    color: inherit;
  }
  
  #sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
  }
  
  /*
   * Content
   */
  
  [role="main"] {
    padding-top: 133px; /* Space for fixed navbar */
  }
  
  @media (min-width: 768px) {
    [role="main"] {
      padding-top: 48px; /* Space for fixed navbar */
    }
  }
  
  /*
   * Navbar
   */
  
   nav {
    box-shadow: 0 .1rem 0.5rem rgba(0,0,0,.15);
   }
  .navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
  }
  
  .navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
  }
  
  .form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
  }
  
  .form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
  }

  img.card-img-top {
    width: 100%;
    height: 15vw;
    object-fit: contain;
  }

  div.card, img.card-img-top {
    border-radius: 0px !important;
    border: 0px;
  }

  /* Color picker */
  .color-picker-container {
    position: relative;
  }
  .color-picker-palette {
    position: absolute;
    z-index: 100;
  }
  
  .color-picker-cover {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
  }
  
  .color-picker-color-background {
    position: fixed;
    height: 35px;
    width: 35px;
    background-color: #999999;
    float: left;
  }

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

#sidebar {
  min-width: 210px;
  max-width: 210px;
}

#content {
  width: 100%;
  padding: 20px;
  min-height: 100vh;
  transition: all 0.3s;
}

.input-error {
  border: red 1px solid;
}

.text-small {font-size:0.8rem;}
  
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}
.stats .card-title {
  height: 60px;
}

.bma-badge-primary {
  color: #fff;
  background-color: #6F97CC;
}
.bma-badge-secondary {
  color: #fff;
  background-color: #F3715A;
}
.btn-bma-primary {
  color: #fff;
  background-color: #6F97CC;
  border-color: #6F97CC;
}
.btn-bma-secondary {
  color: #fff;
  background-color: #F3715A;
  border-color: #F3715A;
}

.bg-bma-primary {
  background-color: #6F97CC!important;
}
.bg-bma-secondary {
  background-color: #F3715A!important;
}
section.main {
    margin-top: 5rem;
}

p.msg_ko {
    color: #f36b21;
}

.form-control {
    border-radius: 0px;
}

div.react-phone-number-input__icon{
    border: none;
}
imput.react-phone-number-input__input{
    border-bottom: none;
}
body,label,input,button {
    color: #000000;
    font-family: 'Imprima' !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}
input, textarea, button, .btn, select {
    border-radius: 0px !important;
}
h1,h2 {
    font-weight: 500;
    color: #747474;
    font-size: 30px;
}
nav {
    border-bottom: 1px solid #ced4da; 
}
a, a:hover,.btn-link, span.nav-link {
    color: #0475B0;
    cursor: pointer;
}
a:hover,.btn-link:hover, span.nav-link:hover {
    color: #91a8bf;
    text-decoration: none;
}
span.nav-link.active {
    text-decoration: underline;
}

